import React from "react";
import ReactPlayer from "react-player";

function VideoFirst() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactPlayer
        url="/Header-Banner-Updated-1.mp4" // Relative path to the video file in the public folder
        playing={true}
        loop={true}
        muted={true}
        controls={false} // Set controls to false to hide the entire controls bar
        config={{
          file: {
            attributes: {
              crossorigin: "anonymous",
            },
          },
        }}
        width="100%"
        height="100%"
        style={{ objectFit: "cover" }}
      />
    </div>
  );
}

export default VideoFirst;
// import React from "react";
// import { useState,useEffect } from "react";
// import img1 from "../../assets/images/Banner 1 (1).webp"
// import img2 from "../../assets/images/Banner 2 (1).webp"
// import img3 from "../../assets/images/Banner 3 (1).webp"
// import img4 from "../../assets/images/Banners 4 (1).webp"
// import img5 from "../../assets/images/Banners 5 (1).webp"
// // import ReactPlayer from "react-player";

// function VideoFirst() {
//   const images = [
//     img1,
//     img2,
//     img3,
//     img4,
//     img5,
//     // Add more image paths as needed
//   ];
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const interval = 3000; // Time in milliseconds between slides (3 seconds)

//   useEffect(() => {
//     // Set up the interval to change the image index
//     const slideInterval = setInterval(() => {
//       setCurrentIndex((prevIndex) =>
//         prevIndex === images.length - 1 ? 0 : prevIndex + 1
//       );
//     }, interval);

//     // Clear the interval when the component unmounts
//     return () => clearInterval(slideInterval);
//   }, [images.length]);

//   return (
//     <div style={{ width: "100%", height: "auto", overflow: "hidden" }}>
//       <img
//         src={images[currentIndex]}
//         alt={`Slide ${currentIndex + 1}`}
//         style={{
//           width: "100%",
//           height: "auto",
//           objectFit: "cover",
//           transition: "opacity 1s ease-in-out",
//         }}
//       />
//     </div>
//   );
// }

// export default VideoFirst;
